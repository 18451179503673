<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color' : ''"
			@input="handleInput($event)"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true"
		>
			<span slot="noOptions">{{ $t("no_options") }}</span>
			<span slot="noResult">{{ $t("no_result") }}</span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError"/>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";
	
	// Services
	import OsymService from "@/services/OsymService";
	
	export default {
		props: {
			value: {
				default: null,
			},
			validateError: {
				type: String,
				default: "",
			},
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			}
		},
		data() {
			return {
				selected: null,
				options: [],
			};
		},
		created() {
			this.getOptions();
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
				const config = {
					params: {
						sort: "code",
						limit: -1,
					},
				};
				this.options = [];
				OsymService.getAll(config)
				           .then((response) => {
					           const data = response.data.data;
					           data.map((item) => {
						           this.options.push({
							           value: item.code,
							           text: item.code + " - " + item.program_name + " (" + (item.scholarship_rate > 0 ? ("%" + item.scholarship_rate) : "Burssuz") + ")",
						           })
					           })
				           })
				           .then(() => {
					           this.selected = this.setSelected(this.value, this.options)
				           })
			}
		}
	}
</script>
